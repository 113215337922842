import { Box, Button, Container, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';
import glenTurretLogo from '../../images/glenturretlogo.png';
import renovoServicesLogo from '../../images/renovoserviceslogo.png';

function GridItem(props) {
  const imageEl = (<Box
    component='img'
    src={props.image}
    width='fill-available'
    alt={props.alt}
  />);

  return (
    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
      <a href={props.url} target='_blank' rel="noreferrer" style={{ width: '100%' }}>
        {props.isSquare ?
          <Button sx={{ width: '100%' }} color='secondary'>{imageEl}</Button> :
          <IconButton sx={{ width: '100%' }} color='secondary'>{imageEl}</IconButton>
        }
      </a>
    </Grid>
  );
}

function Sponsors() {
  return (
    <Container>
      <Typography variant='h2' color='white'>Our Sponsors</Typography>
      <Typography paddingY={2} textAlign='center'>Big thanks to our sponsors! Your support fuels our rally team&apos;s success on and off the track. We couldn&apos;t do it without you!</Typography>
      <Grid container spacing={1} marginTop={0.5} justifyContent='center'>
        <GridItem image={glenTurretLogo} url='https://theglenturret.com/' isMain={true} alt={'The Glen Turret Distillery sponsor\'s logo.'} />
        <GridItem image={renovoServicesLogo} url='https://www.renovoservices.co.uk/' isSquare={true} alt={'Renovo Services sponsor\'s logo.'} />
      </Grid>
    </Container>
  );
}

GridItem.propTypes = {
  image: PropTypes.string,
  url: PropTypes.string,
  isMain: PropTypes.bool,
  isSquare: PropTypes.bool,
  alt: PropTypes.string
};

export default Sponsors;