import { getDatabase, ref, child, get } from 'firebase/database';

export function getCurrentEvent() {
  const dbRef = ref(getDatabase());
  return get(child(dbRef, 'currentEvent')).then((snapshot) => {
    if (snapshot.exists()) {
      return snapshot.val();
    }
  }).catch((error) => {
    console.error(error);
  });
}