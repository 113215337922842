import React from 'react';
import Home from './home';
import ErrorPage from './ErrorPage';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Gallery from './gallery';
import Team from './team';
import Races from './races';


const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage />
  },
  {
    path: '/gallery',
    element: <Gallery />,
    errorElement: <ErrorPage />
  },
  {
    path: '/team',
    element: <Team />,
    errorElement: <ErrorPage />
  },
  {
    path: '/races',
    element: <Races />,
    errorElement: <ErrorPage />
  },
]);

function App() {

  return (
    <>
      <Header />
      <RouterProvider router={router} />
      <Footer />
    </>
  );
}

export default App;