import React from 'react';
import { Box, Container, Grid, Typography, Paper } from '@mui/material';
import firstRaceImage from '../../images/first_race.jpg';

function Story() {
  return (
    <Container>
      <Typography variant='h2' color='white' marginY={2}>Our Story</Typography>
      <Grid container>
        <Grid item md={4}>
          <Paper elevation={3} sx={{ padding: 1 }}>
            <Box
              component='img'
              src={firstRaceImage}
              width='100%'
              alt='Lochlan (driver) and Cameron (navigator) standing infront of the Ford Fiesta as it was for the first rally, The Snowman'
            />
          </Paper>
        </Grid>
        <Grid item md={8} textAlign='center' paddingX={2}>
          <Typography>
            <p>
              Introducing our rally team, proudly representing Livingston, Scotland, and led by the dynamic duo of driver Lochlan McIver and navigator Cameron Morrison. Their journey is not just about speed and precision but also a testament to friendship, passion, and perseverance.
            </p>
            <p>
            Their story began in July 2022 when Lochlan, just 16 years old, rolled up his sleeves to start working on their rally car. With sheer determination and countless hours of hard work, he transformed it into a formidable machine, ready to tackle the toughest terrains.
            </p>
            <p>
            In the following months, Lochlan and Cameron meticulously prepared for their debut. Lochlan, having only just passed his road test, embraced the challenge with excitement and nerves.
            </p>
            <p>
            Their moment finally arrived at The Snowman Rally the following year, where they made their debut. Despite being relative newcomers, Lochlan&apos;s talent behind the wheel and Cameron&apos;s precise navigation quickly garnered attention.
            </p>
            <p>
            Their dedication bore fruit when they clinched 1st place in their Class for the 2023 Scottish Rally Championship, marking a triumphant debut season. It was a testament to their teamwork, resilience, and unwavering determination to succeed against all odds.
            </p>
            As they continue to chase their dreams, Lochlan and Cameron&apos;s story inspire us all to believe in the power of friendship, passion, and the relentless pursuit of excellence.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}


export default Story;