import { Box, Typography } from '@mui/material';
import React from 'react';
import underconstructionImage from '../../images/underconstruction.png';

function UnderConstruction() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{
        backgroundColor: 'black',
        borderRadius: 5,
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
        <Typography variant='h2' color='white'>Our site is currently</Typography>
        <img src={underconstructionImage} alt='Image of MMS fiesta without the engine, sitting on driveway.' style={{ width: '60%', margin: 2 }} />
        <Typography variant='h2' color='white'>Under Construction!</Typography>
      </Box>
    </Box>
  );
}

export default UnderConstruction;