import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import App from './components/App';

const firebaseConfig = {
  apiKey: 'AIzaSyBs1hQWfWonsYvGXJSHrCNbMSlwO9zsfI8',
  authDomain: 'mcivermotorsport.firebaseapp.com',
  projectId: 'mcivermotorsport',
  storageBucket: 'mcivermotorsport.appspot.com',
  messagingSenderId: '778232105474',
  appId: '1:778232105474:web:b35b2467b50b771816351b',
  measurementId: 'G-SNX60T7B6Y',
  databaseURL: 'mcivermotorsport-default-rtdb.europe-west1.firebasedatabase.app'
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#29b6f6',
    },
    secondary: {
      main: '#b8860b',
    }
  },
  typography: {
    fontFamily:
      '"Raleway", sans-serif',
    button: {
      fontWeight: 700
    },
  }
});

theme = responsiveFontSizes(theme, { factor: 5 });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
