import React, { useState, useEffect } from 'react';
import { Container, Stack } from '@mui/material';
import Sponsors from './Sponsors';
import Story from './Story';
import EventDisplay from './EventDisplay';
import UnderConstruction from '../common/UnderConstruction';
import { getCurrentEvent } from '../../utils/rtdb';

function Home() {
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    getCurrentEvent().then((ce) => {
      if (ce != null) { setEventData(ce); }
    });
  }, []);

  return (
    <Container maxWidth='lg'>
      <Stack spacing={8} sx={{ marginTop: 2, alignItems: 'center' }}>
        {eventData &&
          <EventDisplay
            name={eventData.name}
            image={eventData.base64Image}
            infoUrl={eventData.infoUrl}
            liveUrl={eventData.liveUrl}
            startDate={eventData.startDate}
            endDate={eventData.endDate}
          />
        }
        <Story />
        <Sponsors />
        <UnderConstruction />
      </Stack>
    </Container>
  );
}

export default Home;