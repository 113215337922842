import React, { useEffect, useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, MenuItem, Button, useTheme, useMediaQuery, useScrollTrigger, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MMSTitle from '../common/MMSTitle';
import CarIcon from './CarIcon';
import header from '../../images/header.png';

const pages = [
  {
    name: 'Home',
    url: '/'
  },
  {
    name: 'Our Team',
    url: '/team'
  },
  {
    name: 'Gallery',
    url: '/gallery'
  },
  {
    name: 'Races',
    url: '/races'
  }
];

function HeaderV2() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [triggerThreshold, setTriggerThreshold] = useState(0);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  useEffect(() => {
    const handleResize = () => {
      const appBarHeight = document.querySelector('header').offsetTop;
      setTriggerThreshold(appBarHeight);
    };

    // Initialize trigger threshold on component mount
    handleResize();

    // Update trigger threshold on window resize
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const isPastHeader = useScrollTrigger({ disableHysteresis: true, threshold: triggerThreshold });

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
  return (
    <>
      <Box>
        <Box sx={{
          position: 'absolute',
          width: '100vw',
          display: 'flex',
          marginLeft: !isMobile && 5,
          marginTop: isMobile ? 1 : 2,
          justifyContent: isMobile && 'center'
        }}>
          <MMSTitle color='white' variant='h1' fontSize='4vw' />
        </Box>
        <img src={header} alt='Image of MMS fiesta rally car in the dark with the lamp pod lights on facing the camera in a drift.' style={{ width: '100vw', height: '20vw' }} />
        {isPastHeader && <Offset />}
      </Box>
      <AppBar sx={{ marginTop: -1 }} position={isPastHeader ? 'fixed' : 'sticky'}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {isPastHeader ?
                <MMSTitle
                  color='white'
                  variant='h5'
                  barSx={{
                    paddingX: '0.1vw',
                    marginX: '0.5vw',
                    borderRadius: 100,
                    backgroundColor: '#b8860b',
                    transform: 'rotate(10deg)'
                  }}
                /> :
                <CarIcon />
              }
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <a href={page.url} style={{ color: 'white', display: 'block' }} key={page.name}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign='center'>{page.name}</Typography>
                    </MenuItem>
                  </a>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1
              }}
            >
              {isPastHeader ?
                <MMSTitle
                  color='white'
                  variant='h5'
                  barSx={{
                    paddingX: '0.1vw',
                    marginX: '0.5vw',
                    borderRadius: 100,
                    backgroundColor: '#b8860b',
                    transform: 'rotate(10deg)'
                  }}
                /> :
                <CarIcon />
              }
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, gap: 2, marginLeft: 5 }}>
              {pages.map((page) => (
                <a href={page.url} style={{ color: 'white', display: 'block' }} key={page.name}>
                  <Button onClick={handleCloseNavMenu}>
                    {page.name}
                  </Button>
                </a>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default HeaderV2;
