import React, { useState } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import galleryMap from './galleryMap.json';
import { Box, Modal, Typography, useTheme, useMediaQuery } from '@mui/material';

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImageGallery() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showFullImage, setShowFullImage] = useState(null);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  };

  return (
    <>
      <ImageList
        sx={{ transform: 'translateZ(0)' }}
        gap={1}
      >
        {galleryMap.map((item) => {
          const cols = item.featured ? 2 : 1;
          const rows = item.featured ? 2 : 1;

          return (
            <ImageListItem
              key={item.img}
              cols={cols}
              rows={rows}
            >
              <img
                {...srcset(item.img, 250, 200, rows, cols)}
                alt={item.title}
                loading='lazy'
                style={{ cursor: 'pointer' }}
                onClick={() => setShowFullImage(item)}
              />
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                title={
                  <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                    {!isMobile &&
                      <Typography variant='subtitle1'>{item.title}</Typography>
                    }
                    <a href={item.url} target='_blank' rel="noreferrer" style={{ color: 'white' }}>
                      <Typography variant='subtitle2'>{item.author}</Typography>
                    </a>
                  </Box>
                }
                position='top'
              />
            </ImageListItem>
          );
        })}
      </ImageList>
      <Modal
        open={showFullImage}
        onClose={() => setShowFullImage(null)}
      >
        {showFullImage ?
          <Box sx={modalStyle}>
            <Box
              component='img'
              width='fill-available'
              src={showFullImage.img}
            />
            <Box>
              <Box display='flex' alignItems='center' justifyContent='space-between' flexWrap='wrap'>
                <Typography variant='body1'>{showFullImage.title}</Typography>
                <a href={showFullImage.url} target='_blank' rel="noreferrer" style={{ color: 'white' }}>
                  <Typography variant='body2'>{showFullImage.author}</Typography>
                </a>
              </Box>
            </Box>
          </Box> :
          <Box></Box>
        }
      </Modal>
    </>
  );
}