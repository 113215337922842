import React from 'react';
import { PropTypes } from 'prop-types';
import { Container, Paper, useTheme, useMediaQuery, Typography, Grid, Stack, Box } from '@mui/material';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { MapContainer, TileLayer } from 'react-leaflet';
import raceList from './raceList.json';
import RaceMarker from './RaceMarker';

function KeyIcon(props) {
  return (
    <DriveEtaIcon
      style={{
        fontSize: 50,
        backgroundColor: props.color,
        padding: 1,
        borderRadius: '50%',
        boxShadow: '2px 2px 5px black'
      }}
      fontSize='large'
    />
  );
}

function Races() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container maxWidth='xl' sx={{ marginY: 4 }}>
      <Grid container flexDirection={isMobile && 'column-reverse'} spacing={2}>
        <Grid item lg={4} xs={12}>
          <Typography variant='h3'>Key:</Typography>
          <Stack spacing={2} padding={3}>
            <Box display='flex' alignItems='center'>
              <KeyIcon color='gold' />
              <Typography variant='h5' marginLeft={2}>Current Race</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <KeyIcon color='goldenrod' />
              <Typography variant='h5' marginLeft={2}>Next Race (less than 7 days)</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <KeyIcon color='lightgreen' />
              <Typography variant='h5' marginLeft={2}>Future Race</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <KeyIcon color='lightblue' />
              <Typography variant='h5' marginLeft={2}>Past Race</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Paper elevation={4} sx={{ padding: 1 }}>
            <MapContainer id='map' style={{ height: isMobile ? '60vh' : '90vh' }} center={[56.389, -4.203]} zoom={isMobile ? 6 : 8}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {raceList.map((raceData) => (<RaceMarker key={raceData.name} data={raceData} />))}
            </MapContainer>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

KeyIcon.propTypes = {
  color: PropTypes.string
};

export default Races;