import { Box, Button, Container, Grid, Paper, Typography, useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { getDomain, getValidImage } from '../common/utils';

function getCountdown (startDate) {
  const eventStartDate = new Date(startDate);
  const currentDate = new Date();
  const difference = eventStartDate - currentDate;

  if (difference <= 0) {
    return 'Event has started';
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

function EventDisplay(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const eventImage = getValidImage(props.image);

  const [isEventDay, setIsEventDay] = useState(false);
  const [isEventEnded, setEventEnded] = useState(false);
  const [countdown, setCountdown] = useState(getCountdown(props.startDate));

  useEffect(() => {
    const checkIsEventDay = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const currentDate = new Date();

      return currentDate >= start && currentDate <= end;
    };

    const checkEventEnded = (endDate) => {
      const end = new Date(endDate);
      const currentDate = new Date();
      return currentDate > end;
    };

    setIsEventDay(checkIsEventDay(props.startDate, props.endDate));
    setEventEnded(checkEventEnded(props.endDate));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getCountdown(props.startDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [props.startDate]);

  return (
    <Container maxWidth='sm'>
      <Paper elevation={8} sx={{ padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <Typography variant='h3' marginBottom={1}>
          {isEventDay ? 'Current Event' :
            isEventEnded ? 'Last Event' :
              'Next Event'
          }
        </Typography>
        {isEventDay || isEventEnded ? eventImage ?
          <Box
            component='img'
            src={props.image}
            width='fill-available'
            alt={`${props.name} logo`}
          /> :
          <Typography variant='h4' fontWeight={700}>{props.name}</Typography> :
          <Box display='flex' flexDirection='column' alignItems='center' textAlign='center'>
            <Typography variant='h4' fontWeight={700}>{props.name}</Typography>
            <Typography variant='h5' marginY={1}>{props.startDate} - {props.endDate}</Typography>
            <Typography variant='h3' fontWeight={700} marginTop={2}>{countdown}</Typography>
          </Box>
        }
        <Grid container spacing={2} marginTop={1}>
          <Grid item md xs={12}>
            <a href={props.infoUrl} target='_blank' rel='noreferrer'>
              <Button
                fullWidth
                variant='outlined'
                size='large'
                disabled={props.infoUrl == null}
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Typography variant='button'>Event Info</Typography>
                <Typography variant='subtitle2'>{getDomain(props.infoUrl)}</Typography>
              </Button>
            </a>
          </Grid>
          {isEventDay &&
            <Grid item md={6} xs={12}>
              <a href={props.liveUrl} target='_blank' rel='noreferrer'>
                <Button
                  fullWidth
                  variant='outlined'
                  size='large'
                  disabled={props.liveUrl == null}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography variant='button'>Live Stream</Typography>
                  <Typography variant='subtitle2'>{getDomain(props.liveUrl)}</Typography>
                </Button>
              </a>
            </Grid>
          }
          <Grid item xs={12} display='flex' justifyContent='center'>
            <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} textAlign='center'>
              Please note these links will redirect you to other sites not within our control.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

EventDisplay.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  infoUrl: PropTypes.string,
  liveUrl: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

EventDisplay.defaultProps = {
  name: 'Event Unknown',
  image: null,
  infoUrl: null,
  liveUrl: null,
  startDate: '1990-01-01',
  endDate: '1990-01-01'
};

export default EventDisplay;