import React from 'react';
import { Container } from '@mui/material';
import ImageGallery from './ImageGallery';

function Gallery() {
  return (
    <Container maxWidth='lg'>
      <ImageGallery />
    </Container>
  );
}

export default Gallery;