export function getDomain(str) {
  if (str) {
    var domainRegex = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)+)(?:\/.*)?/;
    var matches = str.match(domainRegex);
    if (matches && matches.length > 1) {
      return matches[1];
    } else {
      return null;
    }
  }
}

export function getValidImage(base64img) {
  const base64ImageRegex = /^data:image\/(png|jpg|jpeg|gif);base64,/;
  if (base64img && base64ImageRegex.test(base64img)) {
    return base64img;
  } else {
    return null;
  }
}