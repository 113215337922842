import React from 'react';
import { PropTypes } from 'prop-types';
import { Marker, Popup } from 'react-leaflet';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import { Typography, Button, Table, TableHead, TableRow, TableCell, Paper, TableBody, IconButton } from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { getDomain } from '../common/utils';

export default function RaceMarker(props) {
  const raceData = props.data;

  const isFutureRace = (raceDate) => {
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    var date = new Date(raceDate);
    date.setHours(0, 0, 0, 0);

    return today.getTime() <= date.getTime();
  };

  const getColorForDate = () => {
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    var result;
    raceData.seasons.forEach((seasonData) => {
      var date = new Date(seasonData.date);
      date.setHours(0, 0, 0, 0);

      var threeDaysBefore = new Date(seasonData.date);
      threeDaysBefore.setDate(date.getDate() - 7);
      threeDaysBefore.setHours(0, 0, 0, 0);

      if (date.getTime() === today.getTime()) {
        result = 'gold';
      } else if (today.getTime() > threeDaysBefore.getTime() && today.getTime() < date.getTime()) {
        result = 'goldenrod';
      } else if (date < today) {
        result = 'lightblue';
      } else {
        result = 'lightgreen';
      }
    });

    return result || 'lightgreen';
  };

  const icon = L.divIcon({
    className: 'location-marker-icon',
    html: ReactDOMServer.renderToString(
      <DriveEtaIcon
        style={{
          fontSize: 30,
          backgroundColor: getColorForDate(),
          padding: 1,
          borderRadius: '50%',
          marginLeft: '-10px',
          boxShadow: '2px 2px 10px black'
        }}
        fontSize='large'
      />
    )
  });

  if (raceData.coordinates === null) {
    return null;
  }

  return (
    <Marker position={raceData.coordinates.split(',')} icon={icon}>
      <Popup>
        <Paper sx={{ padding: 1 }}>
          <Typography variant='h5' fontWeight={700} paddingBottom={1}>{raceData.name}</Typography>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Video</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {raceData.seasons.map((row) => (
                <TableRow key={row.date} sx={{ backgroundColor: isFutureRace(row.date) && 'darkgreen' }}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>
                    {row.videoUrl &&
                      <a href={row.videoUrl} target='_blank' rel='noreferrer'>
                        <IconButton>
                          <YouTubeIcon />
                        </IconButton>
                      </a>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <a href={raceData.infoUrl} target='_blank' rel='noreferrer'>
            <Button
              fullWidth
              variant='outlined'
              size='large'
              disabled={raceData.infoUrl == null}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 2
              }}
            >
              <Typography variant='button'>Event Info</Typography>
              <Typography variant='subtitle2'>{getDomain(raceData.infoUrl)}</Typography>
            </Button>
          </a>
          <Typography variant='subtitle2' textAlign='center' paddingTop={2}>
            Please note these links will redirect you to other sites not within our control.
          </Typography>
        </Paper>
      </Popup>
    </Marker>
  );
}

RaceMarker.propTypes = {
  data: PropTypes.object
};
