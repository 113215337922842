import { Box, Button, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { useRouteError } from 'react-router-dom';
import errorimage from '../images/errorimage.png';

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Container maxWidth='sm' sx={{ marginTop: 5 }}>
      <Paper elevation={3} sx={{ borderRadius: 4, padding: 4, display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h1' fontWeight={700}>Oops!</Typography>
        <Typography variant='h2' fontWeight={300} sx={{ marginY: 3, marginLeft: 5 }}>Error: {error.status}</Typography>
        <Typography variant='h5'>{error.statusText || error.message}</Typography>
        <Box
          component='img'
          src={errorimage}
          width='fill-available'
          alt='MMS fiesta on its first rally after coming off track and ending up in bushes.'
        />
        <a href='/' style={{ width: '100%' }}>
          <Button fullWidth size='large' variant='contained' >Return to Home</Button>
        </a>
      </Paper>
    </Container>
  );
};

export default ErrorPage;