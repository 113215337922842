import { Box, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';

function MMSTitle(props) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Typography variant={props.variant} color={props.color} fontStyle='italic' fontWeight={700}>McIVER</Typography>
      <Box sx={props.barSx} />
      <Typography variant={props.variant} color={props.color} fontStyle='italic' fontWeight={200}>MOTORSPORT</Typography>
    </Box>
  );
}

MMSTitle.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  barSx: PropTypes.object
};

MMSTitle.defaultProps = {
  color: 'black',
  barSx: {
    paddingX: '0.4vw',
    marginX: '2vw',
    borderRadius: 100,
    backgroundColor: '#b8860b',
    transform: 'rotate(10deg)'
  }
};

export default MMSTitle;